import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../../config/Requests";
import reactRouterScrollTop from "react-router-scroll-top/lib/react-router-scroll-top";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ScrollToTop from "react-router-scroll-top";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import CsvDownload from "react-json-to-csv";
import Loader from "react-loader-spinner";
import RepArabic from "../../reports/IEITI-GGI Licenses Register (Mar 2; filename1=020) - Arabic.pdf";
import RepEnglish from "../../reports/IEITI-GGI Licenses Register (Mar 2; filename1=020) - English.pdf";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
class ReportView extends Component {
  constructor(props) {
    super(props);
    this.state = { PageTitle: "", numPages: null, pageNumber: 1 };
  }
  componentDidMount = () => {
    let uu = this.props.currentPath.split("/").join("");
    let PT = uu + " " + this.props.header;
    this.setState({ PageTitle: PT });
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  render() {
    let dt = { columns: this.state.columns, rows: this.state.rows };
    const { pageNumber, numPages } = this.state;
    return (
      <React.Fragment>
        <ScrollToTop>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="titleMarginTop">{this.state.PageTitle}</h4>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Accordion
                  allowMultipleExpanded={true}
                  allowZeroExpanded={true}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>Arabic Report</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="pdf">
                        <iframe
                          src={RepArabic}
                          width="100%"
                          height="100%"
                          alt="pdf"
                        />
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>English Report</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="pdf">
                        <iframe
                          src={RepEnglish}
                          width="100%"
                          height="100%"
                          alt="pdf"
                        />
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </ScrollToTop>
      </React.Fragment>
    );
  }
}

export default ReportView;
