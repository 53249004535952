import React from "react";
import ReactDOM from "react-dom";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCgWayvPjtfPC94cM94mcTQO_ElFQosuDs&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    Marker: { lng: 47.73913237841383, lat: 30.08798470458935 }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={9} defaultCenter={props.coordinatesCorners[0]}>
    <Marker
      // onClick={this.onMarkerClick}
      name={"Current location"}
      position={props.coordinatesCorners[0]}
    />
    <Polygon
      // Make the Polygon editable / draggable
      path={props.coordinatesCorners}
    />
  </GoogleMap>
));

export default MyMapComponent;
