import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../../config/Requests";
import reactRouterScrollTop from "react-router-scroll-top/lib/react-router-scroll-top";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "react-accessible-accordion/dist/fancy-example.css";
import ScrollToTop from "react-router-scroll-top";
import { MDBDataTable } from "mdbreact";
import CsvDownload from "react-json-to-csv";
import Loader from "react-loader-spinner";
class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TableListing: [],
      TableHeaderListing: [],
      rows: [],
      columns: [],
      showTable: "showTable",
      spinner: false,
      PageTitle: "",
    };
  }
  componentWillUpdate = (prevProps) => {
    if (this.props.header != prevProps.header) {
      this.setState({ showTable: "dontshow", spinner: true });
      this.getDynamicDataTable(prevProps.cases);
    }
  };
  componentDidMount = () => {
    let uu = this.props.currentPath.split("/").join("");
    let PT = uu + " " + this.props.header;
    this.setState({ PageTitle: PT });
    this.setState({ showTable: "dontshow", spinner: true });
    this.getDynamicDataTable(this.props.cases);
  };
  getDynamicDataTable = (url) => {
    Requests.get(url)
      .then((response) => {
        console.log(response.data);
        if (response.data.length != 0) {
          this.setState({
            TableListing: response.data,
            spinner: false,
          });
          console.log(response.data);
          this.restructureData();
        } else {
          this.setState({
            showTable: "dontshow",
            PageTitle: "No data Provided for " + this.props.header,
            spinner: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          spinner: false,
          ShowNoResult: "dontshow",
        });
        alert(error);
      });
  };
  restructureData = () => {
    console.log(this.state.TableListing);
    let headerArr = [];
    const stateheader = this.state.TableListing[0];
    Object.keys(stateheader).forEach((key) =>
      headerArr.push({
        label: key,
        field: key,
        sort: "asc",
      })
    );
    console.log(headerArr);
    this.setState({
      columns: headerArr,
      rows: this.state.TableListing,
    });
    this.setState({
      showTable: "showTable",
      PageTitle: this.props.header,
    });
  };
  render() {
    let dt = { columns: this.state.columns, rows: this.state.rows };
    console.log(dt);
    return (
      <React.Fragment>
        <ScrollToTop>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="titleMarginTop">{this.state.PageTitle}</h4>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Loader
                  visible={this.state.spinner}
                  type="ThreeDots"
                  color="#5aad47"
                  height={100}
                  width={100}
                  timeout={3000000}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <CsvDownload
                  className={
                    "btn btn-outline-success my-2 my-sm-0 " +
                    this.state.showTable
                  }
                  data={dt.rows}
                  filename={this.props.header + "_" + this.props.cases + ".csv"}
                />
                <MDBDataTable
                  className={this.state.showTable + " "}
                  striped
                  hover
                  data={dt}
                  id="table-to-xls"
                />
              </div>
            </div>
          </div>
        </ScrollToTop>
      </React.Fragment>
    );
  }
}

export default TableView;
