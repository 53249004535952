import React, { Component } from "react";
class UserFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <footer className="page-footer font-small fl-page-footer-wrap">
        <div className="container-fluid text-center text-md-left">
          <hr className="Footerhr" />
          <div className="row d-flex align-items-center">
            <div className="col-md-8 col-lg-8">
              <p className="text-center text-md-left">
                © 2020 Copyright:
                <a>
                  <strong> Haitham Ikkawy</strong>
                </a>
              </p>
            </div>
            <div className="col-md-4 col-lg-4 ml-lg-0">
              <div className="text-center text-md-right">
                <ul className="list-unstyled list-inline">
                  {/* <li className="list-inline-item">
                    <a className="btn-floating btn-sm rgba-white-slight mx-1">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="btn-floating btn-sm rgba-white-slight mx-1">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="btn-floating btn-sm rgba-white-slight mx-1">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li> */}
                  <li className="list-inline-item">
                    <a
                      className="btn-floating btn-sm rgba-white-slight mx-1"
                      href="https://www.linkedin.com/in/haitham-ikkawy-634ab8145"
                      target="blank"
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default UserFooter;
