import React, { Component } from "react";

import { BrowserRouter, Switch, Link } from "react-router-dom";
import { Route } from "react-router";
import { Helmet } from "react-helmet";
import ScrollToTop from "react-router-scroll-top";
import logo from "./logo.svg";
import "./App.css";
import "./css/Libraries/bootstrap.min.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";

import auth from "./config/auth";
import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import "./css/Libraries/animate.css";
import "./css/Libraries/hamburgers.min.css";
import "./css/Libraries/select2.min.css";
import "./css/util.css";

import Home from "./components/Home";
import Wellbore from "./components/Wellbore";
import Licence from "./components/Licence";
import BAA from "./components/BAA";
import Field from "./components/Field";
import Discovery from "./components/Discovery";
import Company from "./components/Company";
import Survey from "./components/Survey";
import Facility from "./components/Facility";
import TUF from "./components/TUF";
import Stratigraphy from "./components/Stratigraphy";
import Error from "./components/Error";
// import wellboreAttributes from "./components/wellboreViews/WellboreAttributes";
import "./css/main.css";
import LocalStorage from "./config/LocalStorage";
class UserApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSignOut = () => {
    auth.logout();
  };
  componentDidMount = () => {};
  componentWillUpdate = (prevProps) => {
    if (this.props.header != prevProps.header) {
      var path = window.location.href;
      var domain = path.split("/");
      LocalStorage.setStorage(
        "Current Page",
        domain[domain.length - 1],
        () => {}
      );
    }
  };
  render() {
    var path = " - " + localStorage.getItem("Current Page");
    return (
      <div className="box">
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>PCLD</title>
            <link
              rel="icon"
              type="image/png"
              href="images/icons/icon.ico"
              sizes="16x16"
            />
          </Helmet>
        </div>

        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <ProtectedRoute path="/" component={Home} exact />
              <ProtectedRoute path="/Wellbore" component={Wellbore} exact />
              <ProtectedRoute path="/Licence" component={Licence} exact />
              <ProtectedRoute path="/BAA" component={BAA} exact />
              <ProtectedRoute path="/Field" component={Field} />
              <ProtectedRoute path="/Discovery" component={Discovery} exact />
              <ProtectedRoute path="/Company" component={Company} exact />
              <ProtectedRoute path="/Survey" component={Survey} exact />
              <ProtectedRoute path="/Facility" component={Facility} exact />
              <ProtectedRoute path="/TUF" component={TUF} exact />
              <ProtectedRoute
                path="/Stratigraphy"
                component={Stratigraphy}
                exact
              />
              {/*<ProtectedRoute*/}
              {/*  path="/wellboreAttributes"*/}
              {/*  component={wellboreAttributes}*/}
              {/*  exact*/}
              {/*/>*/}
              <Route component={Error} exact />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default UserApp;

window.onbeforeunload = function () {
  localStorage.clear();
};
