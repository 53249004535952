import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../config/Requests";
import SideNavigation from "./HeaderFooterComponents/SideNavigation";
import DropdownTreeSelect from "react-dropdown-tree-select";
import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import TreeData from "./jsonTreeData/CompanyTreeData.json";
import LocalStorage from "../config/LocalStorage";
import PageView from "./views/PageView";
import AttributesView from "./views/AttributesView";
import SearchView from "./views/SearchView";
import TableView from "./views/TableView";
import DefinitionView from "./views/DefinitionView";
import ReportView from "./views/ReportView";
import BreadCrumbShared from "./SharedComponents/BreadCrumbShared";
class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intentViewKey: null,
      intentView: null,
      checkParent: null,
      Breadcrumb: [],
      childLeftMenu: "",
      parentLeftMenu: "",
      currentPath: "",
    };
  }

  componentDidMount = () => {
    console.log(this.props.location.pathname);
    let CP = this.props.location.pathname.split("/").join("");
    this.setState({ currentPath: CP });
    var path = CP;
    LocalStorage.setStorage("Current Page", path, () => {});
  };

  renderSwitchOnCompanyViews = () => {
    if (this.state.intentViewKey !== null && this.state.checkParent !== true) {
      let checkAtt = this.state.intentViewKey.endsWith("Attributes");
      let checkTableView = this.state.intentViewKey.endsWith("Table");
      let checkPageView = this.state.intentViewKey.endsWith("Page");
      let checkSearchView = this.state.intentViewKey.endsWith("Search");
      let CheckDefinitionView = this.state.intentViewKey.endsWith("Definition");
      let CheckReportView = this.state.intentViewKey.endsWith("Report");

      let axiosURL = this.state.intentView.split(" ").join("");
      if (checkAtt) {
        return (
          <AttributesView
            header={this.state.intentView}
            currentPath={this.props.location.pathname}
            cases={this.props.location.pathname + "AttributeTable"}
          />
        );
      } else if (CheckReportView) {
        return (
          <ReportView
            header={this.state.intentView}
            cases={"/Get" + this.state.currentPath + "SearchView"}
            currentPath={this.props.location.pathname}
          />
        );
      } else if (checkTableView) {
        return (
          <TableView
            header={this.state.intentView}
            cases={"/Get" + this.state.currentPath + "TableView" + axiosURL}
            currentPath={this.props.location.pathname}
          />
        );
      } else if (checkPageView) {
        return (
          <PageView
            header={this.state.intentView}
            cases={axiosURL}
            currentPath={this.props.location.pathname}
          />
        );
      } else if (checkSearchView) {
        return (
          <SearchView
            header={this.state.intentView}
            cases={"/Get" + this.state.currentPath + "SearchView"}
            currentPath={this.props.location.pathname}
          />
        );
      } else if (CheckDefinitionView) {
        return (
          <DefinitionView
            header={this.state.intentView}
            cases={"/Defs"}
            currentPath={this.props.location.pathname}
          />
        );
      } else {
        return (
          <DefinitionView
            header={this.state.intentView}
            cases={"/Defs"}
            currentPath={this.props.location.pathname}
          />
        );
      }
    } else {
      return (
        <DefinitionView
          header={this.state.intentView}
          cases={"/Defs"}
          currentPath={this.props.location.pathname}
        />
      );
    }
  };

  handleMenuClick = (key, label, parent) => {
    console.log(parent);
    if (parent !== true) {
      localStorage.clear();
      let CP = parent.split("/").join(" / ");
      LocalStorage.setStorage("left Menu parent", CP, () => {});
      LocalStorage.setStorage("left Menu Child", label, () => {});
      var child = localStorage.getItem("Page Name");
      var child = localStorage.getItem("left Menu Child");
      var parent = localStorage.getItem("left Menu parent");
      this.setState({
        intentViewKey: key,
        intentView: label,
        checkParent: parent,
        childLeftMenu: child,
        parentLeftMenu: parent,
      });
      console.log(this.state);
      this.renderSwitchOnCompanyViews();
    }
  };
  render() {
    return (
      <div>
        <BreadCrumbShared
          CurrentPage={this.props.location.pathname.split("/").join("")}
          childLeftMenu={this.state.childLeftMenu}
          parentLeftMenu={this.state.parentLeftMenu}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="trackPreview col-lg-3 col-sm-12">
              {" "}
              <div className="mt-2 titleMarginTop">
                <TreeMenu
                  data={TreeData}
                  onClickItem={({ key, label, parent, ...props }) => {
                    this.handleMenuClick(key, label, parent);
                  }}
                >
                  {({ items }) => (
                    <ul className="tree-item-group">
                      {items.map(({ key, onClickItem, ...props }) => (
                        <ItemComponent key={key} {...props} />
                      ))}
                    </ul>
                  )}
                </TreeMenu>
              </div>
            </div>
            <div className="trackPreview col-lg-9 col-sm-12">
              {this.renderSwitchOnCompanyViews()}
            </div>
          </div>{" "}
        </div>
      </div>
    );
  }
}

export default Company;
