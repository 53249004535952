import React from "react";
import { Route, Redirect } from "react-router-dom";
import UserFooter from "../HeaderFooterComponents/UserFooter";
import UserNavigation from "../HeaderFooterComponents/UserNavigation";
import SideNavigation from "../HeaderFooterComponents/SideNavigation";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <div>
      <UserNavigation />
      <Route
        {...rest}
        render={(props) => {
          if (true) {
            return (
              <Component
                {...props}
                page={localStorage.getItem("Current Page")}
              />
            );
          } else {
            return (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            );
          }
        }}
      />
      <UserFooter />
    </div>
  );
};
