import "./App.css";
import "./css/Libraries/bootstrap.min.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./css/Libraries/animate.css";
import "./css/Libraries/hamburgers.min.css";
import "./css/Libraries/select2.min.css";
import "./css/util.css";
import "./css/main.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider, FormattedMessage } from "react-intl";
import App from "./App";
ReactDOM.render(
  <IntlProvider locale="en">
    <App />
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
