import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../../config/Requests";
import reactRouterScrollTop from "react-router-scroll-top/lib/react-router-scroll-top";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ScrollToTop from "react-router-scroll-top";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import CsvDownload from "react-json-to-csv";
import Loader from "react-loader-spinner";
class SearchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      columns: [],
      searchText: null,
      apiPath: null,
      SearchDetails: [],
      excelName: "",
      showTable: "dontshow",
      ShowNoResult: "dontshow",
      spinner: false,
      PageTitle: "",
    };
  }
  componentDidMount = () => {
    let uu = this.props.currentPath.split("/").join("");
    let PT = uu + " " + this.props.header;
    this.setState({ PageTitle: PT });
  };
  HandleChange = (e) => {
    this.setState({ searchText: e.target.value });
  };
  restructureData = () => {
    console.log(this.state.SearchDetails);
    let headerArr = [];
    const stateheader = this.state.SearchDetails[0];
    Object.keys(stateheader).forEach((key) =>
      headerArr.push({
        label: key,
        field: key,
        sort: "asc",
      })
    );
    console.log(headerArr);
    this.setState({
      columns: headerArr,
      rows: this.state.SearchDetails,
    });
    this.setState({
      showTable: "showTable",
      // PageTitle: this.props.header,
    });
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      spinner: true,
      ShowNoResult: "dontshow",
    });
    const SH = this.state.searchText;
    Requests.post(this.props.cases, { SH })
      .then((response) => {
        if (response.data.length != 0) {
          this.setState({
            showTable: "showTable",
            ShowNoResult: "dontshow",
            SearchDetails: response.data,
            excelName: this.props.header,
          });
          this.restructureData();

          this.setState({ spinner: false });
        } else {
          this.setState({
            SearchDetails: [],
            showTable: "dontshow",
            ShowNoResult: "showTable",
          });

          this.setState({ spinner: false });
        }
        console.log(this.state);
      })
      .catch((error) => {
        this.setState({
          spinner: false,
          ShowNoResult: "dontshow",
        });
        alert(error);
      });
  };
  render() {
    let dt = { columns: this.state.columns, rows: this.state.rows };
    return (
      <React.Fragment>
        <ScrollToTop>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="titleMarginTop">{this.state.PageTitle}</h4>
                <hr />
                <form className="" method="post" onSubmit={this.handleSearch}>
                  <div className="wrap-input100">
                    <input
                      className="input100"
                      placeholder="Search Here"
                      onChange={this.HandleChange}
                      required
                    />
                    <span className="focus-input100" />
                    <span className="symbol-input100">
                      <i className="fa fa-search " aria-hidden="true" />
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Loader
                  visible={this.state.spinner}
                  //visible={true}
                  type="ThreeDots"
                  color="#5aad47"
                  height={100}
                  width={100}
                  timeout={3000000} //3 secs
                />
                <h5 className={this.state.ShowNoResult}>
                  No Record Matches Your Search
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <CsvDownload
                  className={
                    "btn btn-outline-success my-2 my-sm-0 " +
                    this.state.showTable
                  }
                  data={dt.rows}
                  filename={this.state.excelName + ".csv"}
                />
                <MDBDataTable
                  className={this.state.showTable + " "}
                  striped
                  hover
                  data={dt}
                  id="table-to-xls"
                />
              </div>
            </div>
          </div>
        </ScrollToTop>
      </React.Fragment>
    );
  }
}

export default SearchView;
