import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../../config/Requests";
import AttributesTable from "../AttributesTable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Wellbore from "../Wellbore";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import ScrollToTop from "react-router-scroll-top";
import CsvDownload from "react-json-to-csv";
import Loader from "react-loader-spinner";
class AttributesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllAttData: null,
      TableNames: [],
      Columns: [],
      showTable: "dontshow",
      ShowNoResult: "dontshow",
      PageTitle: "",
    };
  }
  componentDidMount = () => {
    let uu = this.props.currentPath.split("/").join("");
    let PT = uu + " " + this.props.header;
    this.setState({ PageTitle: PT });

    this.setState({ spinner: true });
    let url = this.props.cases;
    let CP = this.props.currentPath.split("/").join("");
    Requests.post("AttributeTable", { Path: CP })
      .then((response) => {
        if (response.data.length != 0) {
          this.setState({ spinner: false, showTable: "showTable" });
          console.log(response.data);
          this.setState({ AllAttData: response.data });
          this.setState({ TableNames: this.state.AllAttData[0] });
          this.setState({ Columns: this.state.AllAttData[1] });
          console.log(this.state);
        }
      })
      .catch((error) => {
        this.setState({
          spinner: false,
          ShowNoResult: "dontshow",
        });
        alert(error);
      });
  };
  renderAccordion = () => {
    console.log(this.state.AllAttData);
    return this.state.TableNames.map((attr, index) => {
      let BeforeTN = "Sub level - ";
      if (attr.endsWith("_main")) {
        BeforeTN = "Main level - ";
      }
      return (
        <AccordionItem key={index}>
          <AccordionItemHeading>
            <AccordionItemButton>
              {BeforeTN + attr.split("_").join(" ")}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {this.renderTables(attr, index)}
          </AccordionItemPanel>
        </AccordionItem>
      );
    });
  };
  renderDataTable = (TN) => {
    return this.state.Columns.map((attr, index) => {
      console.log(attr[index].TABLE_NAME);
      if (TN === attr[index].TABLE_NAME) {
        return (
          <tr>
            <td>{attr[index].COLUMN_NAME.split("_").join(" ")}</td>
            <td>{attr[index].COLUMN_NAME}</td>
            <td>{attr[index].DATA_TYPE.split("_").join(" ")}</td>
          </tr>
        );
      }
    });
  };
  renderTables = (attr, index) => {
    return (
      <React.Fragment>
        <ReactHTMLTableToExcel
          id="exTable"
          className="btn btn-outline-success my-2 my-sm-0"
          table={index}
          filename={this.props.header + " attribute description"}
          sheet="General"
          buttonText="Download as XLS"
        ></ReactHTMLTableToExcel>
        <table className="table table-striped mt-2" id={index}>
          <thead>
            <tr>
              <th scope="col">Heading</th>
              <th scope="col">Tag</th>
              <th scope="col">Data type</th>
            </tr>
          </thead>
          <tbody>{this.renderDataTable(attr)}</tbody>
        </table>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <ScrollToTop>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="titleMarginTop">
                  {this.state.PageTitle} Description
                </h4>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Loader
                  visible={this.state.spinner}
                  type="ThreeDots"
                  color="#5aad47"
                  height={100}
                  width={100}
                  timeout={3000000}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Accordion
                  allowMultipleExpanded={true}
                  allowZeroExpanded={true}
                >
                  {this.renderAccordion()}
                </Accordion>
              </div>
            </div>
          </div>
        </ScrollToTop>
      </React.Fragment>
    );
  }
}

export default AttributesView;
