import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../../config/Requests";
import reactRouterScrollTop from "react-router-scroll-top/lib/react-router-scroll-top";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MyMapComponent from "../map";
import ScrollToTop from "react-router-scroll-top";
import LocalStorage from "../../config/LocalStorage";
import Loader from "react-loader-spinner";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CsvDownload from "react-json-to-csv";
import "react-accessible-accordion/dist/fancy-example.css";
class PageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Coordinates: [],
      intentPath: "",
      Listing: [],
      selectedItem: null,
      selectedItemText: null,
      Details: [],
      AccordionTitles: [],
      cases: null,
      Coordinates: [],
      modal: false,
      excelName: "",
      showSelect: "dontshow",
      showDownloadButton: "dontshow",
      showAccordion: "dontshow",
      showNoRecord: "dontshow",
      spinner: false,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  componentWillUpdate = (prevProps) => {
    if (this.props.header !== prevProps.header) {
      let intentPath = this.props.currentPath.split("/").join("");
      let filter = this.props.cases;
      this.setState({
        intentPath: this.props.currentPath.split("/").join(""),
        cases: this.props.cases,
        Listing: [],
        Details: [],
        spinner: true,
        showSelect: "dontshow",
        showDownloadButton: "dontshow",
        showAccordion: "dontshow",
        showNoRecord: "dontshow",
      });
      this.getDynamicList(
        this.props.currentPath.split("/").join(""),
        this.props.cases
      );
    }
    LocalStorage.setStorage("Current Title", this.props.header, () => {});
    let currentTitle = LocalStorage.getStorage("Current Title");
  };
  componentDidMount = () => {
    let intentPath = this.props.currentPath.split("/").join("");
    let filter = this.props.cases;
    this.setState({
      intentPath: this.props.currentPath.split("/").join(""),
      cases: this.props.cases,
      spinner: true,
      Listing: [],
      Details: [],
      showSelect: "dontshow",
      showDownloadButton: "dontshow",
      showAccordion: "dontshow",
      showNoRecord: "dontshow",
    });
    this.getDynamicList(intentPath, filter);
  };
  getDynamicList = (currentPath, filter) => {
    Requests.post("/GetListPageView", {
      intent: localStorage.getItem("Current Page"),
      filter: localStorage.getItem("left Menu Child"),
    })
      .then((response) => {
        if (response.data.length == 0) {
          this.setState({
            showNoRecord: "showTable",
            spinner: false,
            showSelect: "dontshow",
          });
        } else {
          this.setState({
            Listing: response.data,
            spinner: false,
            showSelect: "showTable",
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  getCoordinates = (name) => {
    Requests.get("/GetCoordinates?name=" + name)
      .then((response) => {
        var res = response.data;
        if (res.length != 0) {
          var CoordinatesObj = [];
          var DashEscape = response.data.String_Lat_Long.split("-");
          console.log(DashEscape);
          DashEscape.forEach(function (item) {
            var fistStep = item.split(",");
            CoordinatesObj.push({
              lng: parseFloat(fistStep[0]),
              lat: parseFloat(fistStep[1]),
            });
          });
        } else {
          alert("No Corresponding Coordinates on Map");
        }
        this.setState({ Coordinates: CoordinatesObj });
        console.log(this.state.Coordinates);
      })
      .catch((error) => {
        alert(error);
      });
  };
  HandleChange = (e) => {
    if (e.target.value != this.state.intentPath) {
      var target = e.target || e.srcElement;
      this.getCoordinates(e.target.value);
      this.setState({
        selectedItemText: e.target.selecte,
        Details: [],
        selectedItem: e.target.value,
        spinner: true,
        showDownloadButton: "dontshow",
        showAccordion: "dontshow",
      });
      this.getDynamicDataTable("", e.target.value);
    } else {
      this.setState({
        Details: [],
        showDownloadButton: "dontshow",
        showAccordion: "dontshow",
      });
      alert("Please Select " + this.state.intentPath);
    }
  };
  renderSelect = () => {
    return this.state.Listing.map((attr, index) => {
      return (
        <option key={index} value={attr.ID}>
          {attr.OT ? attr.OT + " - " : ""}
          {attr.Name}
        </option>
      );
    });
  };
  getDynamicDataTable = (headerOption, itemId) => {
    this.setState({ Details: [], selectedItem: itemId });
    this.setState({ excelName: this.props.header + "_" });
    Requests.post("/GetDetailsPageView", {
      intent: localStorage.getItem("Current Page"),
      id: itemId,
    })
      .then((response) => {
        console.log(response.data);
        Requests.post("/GetAccordionTitlesPageView", {
          intent: localStorage.getItem("Current Page"),
          id: itemId,
        }).then((response) => {
          this.setState({ AccordionTitles: response.data });
        });
        this.setState({
          Details: response.data,
          spinner: false,
          showSelect: "showTable",
          showDownloadButton: "showTable",
          showAccordion: "showTable",
        });
        this.renderSelect();
      })
      .catch((error) => {
        alert(error);
      });
  };

  renderAccordion = () => {
    return this.state.AccordionTitles.map((attr, index) => {
      return (
        <React.Fragment key={index}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{attr}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="table-responsive">
                {this.renderTables(this.state.Details[index], index)}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </React.Fragment>
      );
    });
  };
  renderNoHeaderTable = (atrr) => {
    return atrr.map((items, index) => {
      return Object.keys(items).map((key) => {
        return (
          <tr key={key}>
            <th className="greenTd">{key}</th>
            <td
              dangerouslySetInnerHTML={{
                __html: items[key],
              }}
            ></td>
          </tr>
        );
      });
    });
  };
  renderTables = (atrr, index) => {
    if (index == 0) {
      return (
        <table className="table table-striped mt-2" id="General">
          <thead>{this.renderNoHeaderTable(atrr)}</thead>
        </table>
      );
    } else {
      if (atrr != undefined && atrr != null && atrr.length != 0) {
        return (
          <table className="table table-striped mt-2" id="General">
            <thead>
              <tr>
                {Object.keys(atrr[0]).map((key) => {
                  return (
                    <th
                      key={key}
                      dangerouslySetInnerHTML={{ __html: key }}
                    ></th>
                  );
                })}
              </tr>
            </thead>
            <tbody>{this.renderTableDetailsContent(atrr)}</tbody>
          </table>
        );
      }
    }
  };
  renderTableDetailsContent = (atrr) => {
    console.log(atrr);
    return atrr.map((items, index) => {
      return (
        <tr key={index}>
          {Object.keys(items).map((key) => {
            return (
              <td
                key={key}
                dangerouslySetInnerHTML={{ __html: items[key] }}
              ></td>
            );
          })}
        </tr>
      );
    });
  };
  render() {
    return (
      <React.Fragment>
        <ScrollToTop>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="titleMarginTop">
                  {this.props.header} {this.state.intentPath}
                </h4>
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <select
                  name="gender"
                  onChange={this.HandleChange}
                  className={this.state.showSelect + " input100"}
                >
                  <option value={this.state.intentPath} defaultValue>
                    Select {this.state.intentPath}
                  </option>
                  {this.renderSelect()}
                </select>
                <h5 className={this.state.showNoRecord}>
                  No Available Records for {this.props.header}{" "}
                  {this.state.intentPath}
                </h5>
              </div>
              <div className="col-lg-2 col-sm-12">
                <CsvDownload
                  className={
                    "btn btn-outline-success my-2 my-sm-0 FW " +
                    this.state.showDownloadButton
                  }
                  data={this.state.Details}
                  filename={this.props.header + "_" + this.props.cases + ".csv"}
                />
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.state.intentPath === "Licence" ? (
                  <button
                    type="button"
                    className={
                      "btn btn-outline-success my-2 my-sm-0 FW " +
                      this.state.showDownloadButton
                    }
                    onClick={this.toggle}
                  >
                    View On Map
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Loader
                  visible={this.state.spinner}
                  type="ThreeDots"
                  color="#5aad47"
                  height={100}
                  width={100}
                  timeout={3000000}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {this.state.Details.length != 0 ? (
                  <Accordion
                    allowMultipleExpanded={true}
                    allowZeroExpanded={true}
                    className={"accordion " + this.state.showAccordion}
                  >
                    {this.renderAccordion()}
                  </Accordion>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Modal isOpen={this.state.modal}>
              <ModalHeader className="modal-header modal-title">
                {this.state.selectedItemText} Map Location
              </ModalHeader>
              <ModalBody>
                <MyMapComponent coordinatesCorners={this.state.Coordinates} />
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.toggle}
                >
                  Close
                </button>
              </ModalFooter>
            </Modal>
          </div>{" "}
        </ScrollToTop>
      </React.Fragment>
    );
  }
}
export default PageView;
