import React, { Component } from "react";
import { NavLink, Link, withRouter, Redirect } from "react-router-dom";
import LocalStorage from "../../config/LocalStorage";
import Logo from "../../images/logo.jpg";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";

class UserNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      searchTab: "",
    };

    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  handleSearch = (event) => {
    event.preventDefault();
    var path = window.location.href;
    var domain = path.split("/");
    LocalStorage.setStorage(
      "Current Page",
      domain[domain.length - 1],
      () => {}
    );
  };
  redirectToGuest = () => {
    return <Redirect to={"/Profile"} />;
  };

  componentDidMount = () => {
    console.log(this.props);
  };
  handlePathChange = () => {
    localStorage.clear();
  };

  render() {
    const links = [
      {
        key: 0,
        to: "/",
        className: "nav-link",
        activeClassName: "active",
        text: "Home",
      },
      {
        key: 1,
        to: "/ReportingView",
        className: "nav-link",
        activeClassName: "active",
        text: "Repoting",
      },
    ];
    const createNavItem = ({ to, className, activeClassName, text, key }) => (
      <NavItem>
        <NavLink
          onClick={this.handlePathChange}
          key={key}
          to={to}
          className={className}
          activeClassName={activeClassName}
          exact
        >
          {text}
        </NavLink>
      </NavItem>
    );
    // return (
    //   <div>
    //     <Navbar color="light" light expand="md">
    //       <a href="http://pcldregister.com/">
    //         <img className="img-thumbnail" alt="Logo" src={Logo} />
    //       </a>
    //       <NavbarToggler onClick={this.toggle} />
    //       <Collapse isOpen={this.state.isOpen} navbar>
    //         <Nav navbar>{links.map(createNavItem)} </Nav>
    //       </Collapse>
    //     </Navbar>
    //     {/* <BreadCrumb /> */}
    //   </div>
    // );
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand">
            <img className="img-thumbnail" alt="Logo" src={Logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  exact
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Wellbore"
                  className="nav-link"
                  activeClassName="active"
                  exact
                >
                  Wellbore
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/Licence"
                  className="nav-link"
                  activeClassName="active"
                  onClick={this.handleTrackRefresh}
                >
                  Licence
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/BAA"
                  className="nav-link"
                  activeClassName="active"
                >
                  BAA
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/Field"
                  className="nav-link"
                  activeClassName="active"
                >
                  Field
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/Discovery"
                  className="nav-link"
                  activeClassName="active"
                >
                  Discovery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/Company"
                  className="nav-link"
                  activeClassName="active"
                >
                  Company
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/Survey"
                  className="nav-link"
                  activeClassName="active"
                >
                  Survey
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/Facility"
                  className="nav-link"
                  activeClassName="active"
                >
                  Facility
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/TUF"
                  className="nav-link"
                  activeClassName="active"
                >
                  TUF
                </NavLink>
              </li>
            </ul>

            {/*<form*/}
            {/*  className="form-inline my-2 my-lg-0"*/}
            {/*  method="post"*/}
            {/*  onSubmit={this.handleSearch}*/}
            {/*>*/}
            {/*  <div className="wrap-input100">*/}
            {/*    <input*/}
            {/*      className="input100"*/}
            {/*      type="text"*/}
            {/*      name="email"*/}
            {/*      placeholder="Search Here"*/}
            {/*      onChange={this.HandleChange}*/}
            {/*      required*/}
            {/*    />*/}
            {/*    <span className="focus-input100" />*/}
            {/*    <span className="symbol-input100">*/}
            {/*      <i className="fa fa-search " aria-hidden="true" />*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</form>*/}
          </div>
        </nav>
      </div>
    );
  }
}

export default UserNavigation;
