import LocalStorage from "./LocalStorage";
import Requests from "./Requests";

class Auth {
  constructor() {
    this.authenticated = this.isAuthenticated();
    this.user = {};
  }

  login(userData, cb) {
    console.log(userData);
    Requests.post("api/v1/auth/login", userData)
      .then(response => {
        let token = response.data.token;
        console.log(token);
        LocalStorage.setStorage("token", token, () => {
          this.authenticated = this.isAuthenticated();
          let tk = LocalStorage.getStorage("token");
        });
        let tk = localStorage.getItem("token");
        let url = "api/v1/user/profile";
        Requests.get(url, tk)
          .then(response => {
            let userID = response.data.data.id;
            let userName = response.data.data.first_name;
            console.log(response);
            let userData = response.data.data;
            console.log(userData);
            LocalStorage.setStorage("ID", userID, () => {
              this.authenticated = this.isAuthenticated();
            });
            LocalStorage.setStorage("userName", userName, () => {
              this.authenticated = this.isAuthenticated();
            });
            window.location.replace("http://localhost:3000");
          })
          .catch(error => {
            //alert(error);
          });
      })
      .catch(error => {
        if (error.response !== undefined) {
          alert(error.response.data.error.message);
        } else {
          //alert(error);
        }
      });
  }
  clearStorage() {
    LocalStorage.removeStorage("token", () => {
      this.authenticated = this.isAuthenticated();
    });
    LocalStorage.removeStorage("userName", () => {
      this.authenticated = this.isAuthenticated();
    });

    LocalStorage.removeStorage("ID", () => {
      this.authenticated = this.isAuthenticated();
    });
  }
  logout() {
    Requests.post("api/v1/auth/logout")
      .then(response => {
        alert(response.data.message);
        this.clearStorage();
        console.log(response.data);
        window.location.reload();
      })
      .catch(error => {
        console.log();
        //alert(error);
      });
  }

  isAuthenticated() {
    return LocalStorage.checkStorage("token");
  }
  isAllowed(user, rights) {
    rights.some(right => user.rights.includes(right));
  }

  hasRole(user, roles) {
    roles.some(role => user.roles.includes(role));
  }
}
export default new Auth();
