import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../config/Requests";
class AttributesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    fetch("/users").then(users => {
      console.log(users.data);
      this.setState({ users });
    });
    // Requests.get("/API/AttributeTable=wellboreAtt")
    //   .then(response => {
    //     console.log(response.data);
    //     this.setState({ tableListing: response.data.data });
    //     console.log(this.state.tableListing);
    //   })
    //   .catch(error => {
    //     alert(error);
    //   });
  };

  GetAndRenderTableData() {
    return this.state.tableListing.map((row, index) => {
      return (
        <tr key={row}>
          <td scope="row">{row}</td>
          <td scope="row">{row}</td>
          <td scope="row">{row}</td>
          <td scope="row">{row}</td>
        </tr>
      );
    });
  }
  render() {
    return (
      <React.Fragment>
        <table className="table table-striped mt-2">
          <tbody>
            <tr>
              <th scope="row">Heading</th>
              <th scope="row">Data type</th>
            </tr>
            <tr>
              <th scope="row">Soft Skills</th>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default AttributesTable;
