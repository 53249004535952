import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DropdownTreeSelect from "react-dropdown-tree-select";
import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import LocalStorage from "../../config/LocalStorage";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
class BreadCrumbShared extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intentViewKey: null,
      intentView: null,
      checkParent: null,
      Breadcrumb: [],
      childLeftMenu: "",
      parentLeftMenu: "",
    };
  }
  componentWillUpdate = (prevProps) => {
    if (this.props.childLeftMenu != prevProps.childLeftMenu) {
      this.buildBread();
    }
  };
  buildBread = () => {
    let CP = this.props.CurrentPage.split("/").join("");
    this.setState({ currentPath: CP });
    LocalStorage.removeStorage("Current Page");
    var path = CP;
    LocalStorage.setStorage("Current Page", path, () => {});

    var child = localStorage.getItem("Page Name");
    var child = localStorage.getItem("left Menu Child");
    var parent = localStorage.getItem("left Menu parent");

    this.setState({
      checkParent: parent,
      childLeftMenu: child,
      parentLeftMenu: parent,
    });
  };
  componentDidMount = () => {
    this.buildBread();
  };

  renderParent = () => {
    console.log(this.state.parentLeftMenu);
    if (this.state.parentLeftMenu != "") {
      return <BreadcrumbItem>{this.state.parentLeftMenu}</BreadcrumbItem>;
    }
  };
  renderChild = () => {
    if (this.state.childLeftMenu) {
      return (
        <BreadcrumbItem active tag="span">
          {this.state.childLeftMenu}
        </BreadcrumbItem>
      );
    }
  };
  render() {
    return (
      <div>
        <Breadcrumb tag="nav" listTag="div" className="greenBorderButtom">
          <BreadcrumbItem>{this.state.currentPath}</BreadcrumbItem>
          {this.renderParent()}
          {this.renderChild()}
        </Breadcrumb>{" "}
      </div>
    );
  }
}

export default BreadCrumbShared;
