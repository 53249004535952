import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../../config/Requests";
import reactRouterScrollTop from "react-router-scroll-top/lib/react-router-scroll-top";
import ScrollToTop from "react-router-scroll-top";
import Loader from "react-loader-spinner";
class DefinitionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      definitions: [],
      showTable: "showTable",
      PageTitle: "",
      spinner: false,
      PageTitle: "",
    };
  }

  componentDidMount = () => {
    let uu = this.props.currentPath.split("/").join("");
    let PT = uu + " " + this.props.header;
    this.setState({ PageTitle: PT });
    this.setState({ showTable: "dontshow", spinner: true, PageTitle: "" });
    let url = this.props.cases;
    let CP = this.props.currentPath.split("/").join("");
    Requests.post(url, { Page: CP })
      .then((response) => {
        if (response.data.length != 0) {
          this.setState({
            definitions: response.data,
            spinner: false,
          });
        }
        console.log(this.state);
      })
      .catch((error) => {
        this.setState({
          spinner: false,
          ShowNoResult: "dontshow",
        });
        alert(error);
      });
  };
  renderDefinitions = () => {
    return this.state.definitions.map((items, index) => {
      return (
        <div>
          <div className="row">
            <div className="col-lg-12">
              <h5>{items.Title}</h5>
              <p
                className="mb-4 mt-2"
                dangerouslySetInnerHTML={{ __html: items.DefinitionText }}
              ></p>
            </div>
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <div>
        <React.Fragment>
          <ScrollToTop>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="titleMarginTop">
                    {this.state.PageTitle} Definition Page
                  </h4>
                  <hr />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <Loader
                    visible={this.state.spinner}
                    type="ThreeDots"
                    color="#5aad47"
                    height={100}
                    width={100}
                    timeout={3000000}
                  />
                </div>
              </div>
              {this.renderDefinitions()}
            </div>
          </ScrollToTop>
        </React.Fragment>
      </div>
    );
  }
}

export default DefinitionView;
