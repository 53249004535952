import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Requests from "../config/Requests";
import SideNavigation from "./HeaderFooterComponents/SideNavigation";
import OilDrop from "../images/oil_drop.png";
import LocalStorage from "../config/LocalStorage";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RedirectToLicences: false,
      RedirectToTUF: false,
      RedirectToBAA: false,
      RedirectToField: false,
      RedirectToWellbore: false,
      definitions: [],
      currentPath: "",
    };
  }
  renderDefinitions = () => {
    return this.state.definitions.map((items, index) => {
      return (
        <div>
          <div className="mt-4 pl-3">
            <h4 className="TitleColor">{items.Title}</h4>
          </div>
          <div className="courseDescription row p-3">
            <div className="col">
              <p dangerouslySetInnerHTML={{ __html: items.DefinitionText }}></p>
            </div>
          </div>
        </div>
      );
    });
  };
  componentDidMount = () => {
    console.log(this.props.location.pathname);
    let CP = this.props.location.pathname.split("/");
    this.setState({ currentPath: CP });
    LocalStorage.removeStorage("Current Page");
    var path = CP;
    LocalStorage.setStorage("Current Page", path, () => {});
    Requests.post("/Defs", { Page: "Home" })
      .then((response) => {
        this.setState({
          definitions: response.data,
        });
        console.log(this.state);
      })
      .catch((error) => {
        alert(error);
      });
  };
  setRedirectToLicences = () => {
    this.setState({
      RedirectToLicences: true,
    });
  };
  setRedirectToTUF = () => {
    this.setState({
      RedirectToTUF: true,
    });
  };
  setRedirectToBAA = () => {
    this.setState({
      RedirectToBAA: true,
    });
  };
  setRedirectToField = () => {
    this.setState({
      RedirectToField: true,
    });
  };
  setRedirectToWellbore = () => {
    this.setState({
      RedirectToWellbore: true,
    });
  };
  renderRedirectToLicences = () => {
    if (this.state.RedirectToLicences) {
      return (
        <Redirect
          to={{
            pathname: "/Licence",
            state: {
              id: this.state.trackId,
              game_url: this.state.game_url,
              game_id: this.state.game_id,
              user_id: this.state.user_id,
            },
          }}
        />
      );
    }
  };
  renderRedirectToTUF = () => {
    if (this.state.RedirectToTUF) {
      return (
        <Redirect
          to={{
            pathname: "/TUF",
            state: {
              id: this.state.trackId,
              game_url: this.state.game_url,
              game_id: this.state.game_id,
              user_id: this.state.user_id,
            },
          }}
        />
      );
    }
  };
  renderRedirectToBAA = () => {
    if (this.state.RedirectToBAA) {
      return (
        <Redirect
          to={{
            pathname: "/BAA",
            state: {
              id: this.state.trackId,
              game_url: this.state.game_url,
              game_id: this.state.game_id,
              user_id: this.state.user_id,
            },
          }}
        />
      );
    }
  };
  renderRedirectToField = () => {
    if (this.state.RedirectToField) {
      return (
        <Redirect
          to={{
            pathname: "/Field",
            state: {
              id: this.state.trackId,
              game_url: this.state.game_url,
              game_id: this.state.game_id,
              user_id: this.state.user_id,
            },
          }}
        />
      );
    }
  };
  renderRedirectToWellbore = () => {
    if (this.state.RedirectToWellbore) {
      return (
        <Redirect
          to={{
            pathname: "/Wellbore",
            state: {
              id: this.state.trackId,
              game_url: this.state.game_url,
              game_id: this.state.game_id,
              user_id: this.state.user_id,
            },
          }}
        />
      );
    }
  };
  render() {
    return (
      <div>
        <Breadcrumb tag="nav" listTag="div" className="greenBorderButtom">
          {" "}
          <BreadcrumbItem> Home</BreadcrumbItem>
        </Breadcrumb>
        {/* <Breadcrumb tag="nav" listTag="div" className="greenBorderButtom">
          <BreadcrumbItem tag="a" href="#">
            Home
          </BreadcrumbItem>
          <BreadcrumbItem tag="a" href="#"></BreadcrumbItem>
          <BreadcrumbItem tag="a" href="#"></BreadcrumbItem>
          <BreadcrumbItem active tag="span"></BreadcrumbItem>
        </Breadcrumb> */}
        {this.renderRedirectToLicences()}
        {this.renderRedirectToTUF()}
        {this.renderRedirectToBAA()}
        {this.renderRedirectToField()}
        {this.renderRedirectToWellbore()}

        <div className="container-fluid">
          <div className="row">
            <div className="trackPreview col-lg-3 col-sm-12">
              <div className="mt-4 pl-3">
                <img className="img-thumbnail" src={OilDrop} />
                <div className="mt-4">
                  <h5>Petroleum register</h5>
                  <ul>
                    <li>
                      <a onClick={() => this.setRedirectToLicences()}>
                        Production licences
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.setRedirectToTUF()}>
                        Transport licences
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-4">
                  <h5>Shortcuts</h5>
                  <ul>
                    <li>
                      <a onClick={() => this.setRedirectToBAA()}>
                        Exploitiation licence
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.setRedirectToField()}>
                        Field production
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.setRedirectToWellbore()}>
                        Wellbore statistics
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="trackPreview col-lg-9 col-sm-12">
              {this.renderDefinitions()}
            </div>
          </div>{" "}
        </div>
      </div>
    );
  }
}

export default Home;
